<!-- Home.vue -->
<template>
  <div class="bg-black items-center w-full">
    <div class="">
      <!-- First Section -->
      <section class="bg-black text-white max-w-screen-md mx-auto">
        <div class="container mx-auto my-auto items-center">
          <!-- Left Column -->
          <br />
          <div id=" py-4">
            <button class="py-2">
              <router-link to="/">
                <img
                  :src="images[$i18n.locale].main"
                  alt="Use nock"
                  class="w-1/4 mx-auto"
              /></router-link>
            </button>
          </div>
          <br />
          <div class="mx-auto p-5 text-center">
            <h1 class="text-3xl font-bold mb-4">
              {{ $t("views.home.subtitle") }}
            </h1>
            <p class="text-base mb-4 font-regular text-gray-200">
              {{ $t("views.home.title2") }}
            </p>
            <button class="py-2" v-if="!isAndroid">
              <a
                href="https://apps.apple.com/app/nock-instant-connections/id6476958614"
                target="_blank"
              >
                <img
                  src="@/assets/ios-store-badge-en.svg"
                  alt="Download for iOS"
                  class="download-button-ios mx-auto"
                />
              </a>
            </button>
            <div v-if="isAndroid">
              <br />
              <br />
              <p class="text-base mb-4 font-bold text-gray">
                {{ $t("views.download.soon") }}
              </p>
              <img
                src="@/assets/googleplay.png"
                alt="Download for Google Play"
                class="download-button-ios mx-auto"
              />
            </div>
            <br />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";

export default {
  name: "download-view",
  data() {
    return {
      images: {
        es: {
          main: require("@/assets/nock_name_full_white.png"),
          discovery: require("@/assets/discovery_es.png"),
          chat: require("@/assets/chat_es.png"),
          public: require("@/assets/public_es.png"),
        },
        en: {
          main: require("@/assets/nock_name_full_white.png"),
          discovery: require("@/assets/discovery_en.png"),
          chat: require("@/assets/chat_en.png"),
          public: require("@/assets/public_en.png"),
        },
      },
    };
  },
  computed: {
    isAndroid() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android/i.test(userAgent);
    },
  },
  setup() {
    const iosUrl =
      "https://apps.apple.com/app/nock-instant-connections/id6476958614";

    const detectDeviceOS = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log("User Agent:", userAgent);

      if (/android/i.test(userAgent)) {
        return "Android";
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      } else {
        return "unknown";
      }
    };

    const redirectToStore = () => {
      const deviceOS = detectDeviceOS();
      console.log("Detected OS:", deviceOS); // Confirmar ejecución
      switch (deviceOS) {
        case "Android":
          break;
        case "iOS":
          window.location.href = iosUrl;
          break;
        default:
          console.log("Unknown device OS");
      }
    };

    onMounted(redirectToStore);
    return {};
  },
};
</script>

<style scoped>
/* Add your styling here */
/* Responsive YouTube Video Aspect Ratio */
.aspect-w-16 {
  width: 100%;
}

.download-button-google {
  height: 57px !important;
}
.download-button-ios {
  height: 40px !important;
}

.aspect-h-9 {
  padding-bottom: 56.25%;
}

.download-button {
  color: #ffffff;
  transition: background-color 0.3s ease;
  background-color: #f30051;
}

.download-button:hover {
  color: #ffffff;
  background-color: rgb(195, 27, 75);
}
</style>
