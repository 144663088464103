<template>
  <div
    class="bg-white max-w-screen-2xl w-full max-w-screen-xl mx-auto py-10"
  >
    <div
      class="lg:ml-40 lg:mr-40 md:ml-5 md:mr-5 ml-5 mr-5 rounded bg-white text-justify"
    >
      <h1 class="text-center text-xl font-bold mb-1 pt-10">
        {{ $t("faqs") }}
      </h1>
      <h4 class="text-center text-sm font-medium mb-2 pt-5">
        {{ $t("views.faqs.subtitle") }}
        <a
          href="mailto:contact@getnock.com"
          class="text-black text-sm font-bold"
          >contact@getnock.com</a
        >
      </h4>

      <div v-for="(faq, index) in faqs" :key="index" class="px-5">
        <h2 @click="toggleAccordion(index)">
          <button
            type="button"
            class="flex items-center justify-between w-full py-5 font-bold rtl:text-right border-b border-gray-200 dark:border-gray-700 gap-3"
          >
            <span> {{ faq.question }}</span>
            <svg
              data-accordion-icon
              class="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5 5 1 1 5"
              />
            </svg>
          </button>
        </h2>
        <div v-if="faq.open">
          <div class="py-5 border-b border-gray-200">
            <p class="mb-2 mt-2 text-sm">{{ faq.answer }}</p>
          </div>
        </div>
      </div>
   
      <section class="text-center py-16 px-5 bg-white">
        <div class="container mx-auto">
          <h2 class="text-xl text-black font-bold mb-4">
            {{ $t("views.home.contactTitle") }}
          </h2>
          <p class="mb-2 text-base font-regular text-black-200">{{ $t("views.home.contactDescription") }}</p>
          <a href="mailto:contact@getnock.com" class="text-base  text-black-200"
            >contact@getnock.com</a
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "faqs-view",
  data() {
    return {
      faqs: this.$tm("views.faqs.questions"),
    };
  },
  methods: {
    toggleAccordion(index) {
      this.faqs[index].open = !this.faqs[index].open;
    },
  },
};
</script>

<style scoped>
/* Add your styling here */
</style>
