import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    home: 'Home',
    faqs: 'FAQs',
    terms: 'Terms & Conditions',
    cookie: 'Cookie Policy',
    privacy: 'Privacy Policy',
    download: 'Download',
    madeIn: 'Made in Medellín',
    copyRight: 'COPYRIGHT © 2024 Nock App LLC - ALL RIGHTS RESERVED',
    views: {
      home: {
        title: 'An app designed to connect you in real-time with people in your immediate surroundings using AI, whether for friendship, shared interests, or professional networking.',
        title2: 'Experience the magic of instant connections.',
        subtitle2: 'Coming soon to stores!',
        subtitle: 'Lock?, No? Ok. Nock',
        intro: 'Embark on your journey of instant connections',
        misionTitle: "Expanding your digital identity",
        misionDescription: "Our focus is not that of a dating app, but rather a tool that amplifies and shares your digital identity to build valuable networks, follower growth, and open opportunities for meaningful connections in the world around you.",
        discoveryTitle: "Connecting with new people nearby is a breeze",
        discoveryDescription: "Discover people in real-time within 1 km and use our AI to connect with those who share your interests, transforming every moment.",
        chatTitle: "Break the ice with our 'Nock Nock' feature!",
        chatDescription: "Extend invitations to connect and unlock the door to your social networks and private messages that last 10 min, only if both are nearby.",
        findTitle: "Step into the lively world of real-time chat!",
        findDescription: "Exchange messages instantly with nearby users, fostering dynamic and interactive conversations that enrich and enhance your local experience.",
        learnTitle: "Learn more",
        learnDescription: "Discover everything you need to know.",
        learnButton: "Explore FAQs",
        contactTitle: "Contact us",
        contactDescription: "We value your feedback! Please feel free to send us an email."
      },
      download: {
        soon: 'Coming soon to'
      },
      faqs: {
        subtitle: 'We value your feedback! Please reach us if you cannot find an answer to your question at',
        questions: [{
          question: "What is 'Nock' and how does it work?",
          answer:
            "Nock is a social mobile app that connects you with people nearby. With Nock, you can discover and connect with like-minded individuals and expand your social network, both personally and professionally. Nock focuses on building meaningful relationships and fostering face-to-face interactions at local events and situations. Our innovative platform utilizes geolocation and proximity technologies to show you relevant opportunities near you, helping you to connect with other users while engaging in real-life experiences. Join the Nock community and broaden your circle of contacts in an authentic and genuine way.",
          open: true,
        },
        {
          question: "Is it a dating app?",
          answer:
            "Nock is not a dating app, but rather a tool to forge timely and valuable connections, whether for friendship, shared interests, or professional networking, but not exclusively for dating. The Nock experience is straightforward and meaningful, allowing users to take their social presence to a more personal and contextual level.",
          open: false,
        },
        {
          question: "What is the 'Radar' and how does it work?",
          answer:
            "The 'Radar' is a key functionality within the Nock mobile app, designed to connect users with people in their immediate environment. It uses geolocation technologies (GPS) and proximity, such as Bluetooth and Wi-Fi networks, to determine users' positions and allow them to share their profile within a maximum radius of 1 km. The Radar also uses AI to show compatibility percentages based on common interests.",
          open: false,
        },
        {
          question: "How can I use the 'Nock Nock' feature?",
          answer:
            "Simply turn on the radar and find a user you'd like to connect with nearby you, send them a 'Nock Nock' invitation, and if accepted, you'll be able to send private messages and access their social media links.",
          open: false,
        },
        {
          question: "What makes 'Open Chat' unique?",
          answer:
            "'Open Chat' is our dynamic public chat where every message lasts only 30 minutes. It keeps the conversation lively, spontaneous, and ensures a fresh chat experience in real time.",
          open: false,
        },
        {
          question: "Is 'Nock' available for both iOS and Android?",
          answer:
            "Currently, 'Nock' is available for download only on iOS platforms.",
          open: false,
        },
        {
          question: "Can I customize who can see my information?",
          answer:
            "Absolutely! 'Nock' allows you to control your information privacy settings, deciding what social media links are public and what are just for your Nocks. You can do this on your profile or another user's profile.",
          open: false,
        },
        {
          question: "Are the 30-minute messages in 'Open Chat' permanently deleted?",
          answer:
            "Yes, messages in 'Open Chat' automatically vanish after 30 minutes, providing a unique and instant chat experience.",
          open: false,
        },
        {
          question: "What kind of information can I share on my profile?",
          answer:
            "You can share your preferred social media links, profile photo, name, personal description, and other interests on your profile, giving others a glimpse into your online presence.",
          open: false,
        },
        {
          question: "Is 'Nock' free to download and use?",
          answer:
            "Yes, 'Nock' is free to download and use. Some premium features may be available through in-app purchases.",
          open: false,
        },
        {
          question: "How secure is the private messaging in 'Nock'?",
          answer:
            "We prioritize user security. Private messaging in 'Nock' is encrypted to ensure a secure and enjoyable communication experience.",
          open: false,
        },
        {
          question: "How can I delete my profile?",
          answer:
            "You can delete your profile by going to your account settings and selecting 'Delete Account'. All your personal data will be permanently removed from our servers within 30 days, though it may take up to six months for complete erasure due to backup and recovery processes.",
          open: false,
        },
        {
          question: "Can I block or report users?",
          answer:
            "Yes, you can block or report users who you find inappropriate or do not wish to connect with. This can be done from their profile or chat screen.",
          open: false,
        },
        {
          question: "How do I know if someone is near me?",
          answer:
            "The Radar feature will notify you when someone with common interests is within 1 km of your location. You can see their profile and compatibility percentage.",
          open: false,
        },
        {
          question: "Can I change my interests and preferences?",
          answer:
            "Yes, you can update your interests and preferences anytime in your profile settings to improve your connection suggestions.",
          open: false,
        },
        {
          question: "How does the compatibility percentage work?",
          answer:
            "Our AI analyzes your profile details, such as interests, hobbies, and personal description, to generate a compatibility percentage with other users.",
          open: false,
        },
        {
          question: "What is 'Favorite' functionality?",
          answer:
            "The 'Favorite' functionality allows you to mark important profiles so they appear first in your Radar and Nocks list.",
          open: false,
        },
        {
          question: "What happens if I ignore a 'Nock Nock' invitation?",
          answer:
            "If you ignore a 'Nock Nock' invitation, it will expire after 24 hours and the sender will not be notified.",
          open: false,
        },
        {
          question: "How do I set up my profile?",
          answer:
            "Setting up your profile is easy. Just fill out your name, photo and add your social media links in the profile section of the app.",
          open: false,
        },
        {
          question: "Can I use 'Nock' to connect for professional purposes?",
          answer:
            "Yes, 'Nock' is designed to help you connect for both personal and professional networking, making it versatile for various connection needs.",
          open: false,
        },
        {
          question: "What if I encounter technical issues?",
          answer:
            "If you encounter any technical issues, please contact our support team through the app or via email, and we will assist you promptly.",
          open: false,
        },
        ],
      },
      terms: {
        text: 'Terms & Conditions of Service',

      },
      privacy: {
        text: 'Privacy Policy',
      },
      eula: {
        text: 'End-User License Agreement',
      },
      cookie: {
        text: 'Cookie Policy',
      },
      delete: {
        title: 'Deletion of Your Account',
        description1: 'Users can delete their accounts at any time from the mobile app, which will result in the permanent deletion of their profile, all associated information, metadata, Nocks, and messages. This action is irreversible, and all user data will be removed from our systems.',
        description2: 'Nock will make every effort to delete user information immediately upon account deletion. However, due to operational issues, there may be delays, and it could take up to six months to fully remove all data. Users are assured that their information will be completely erased as soon as possible within this timeframe.',
        titleSteps: 'Steps to delete your account',
        step1: 'Open the Nock app and go to "Settings" in the drawer menu',
        step2: 'Tap on "Account" in the settings menu',
        step3: 'Tap on "Delete Account" button at the bottom of the screen',
        step4: 'Tap on "Delete forever" button in the confirmation dialog',
      }
    }
  },
  es: {
    home: 'Inicio',
    faqs: 'Preguntas frecuentes',
    terms: 'Términos & Condiciones',
    privacy: 'Política de privacidad',
    cookie: 'Política de cookies',
    download: 'Descargar',
    madeIn: 'Hecho en Medellín',
    copyRight: 'COPYRIGHT © 2024 Nock App LLC - ALL RIGHTS RESERVED',
    views: {
      home: {
        title: 'Una app diseñada para conectarte en tiempo real con personas en tu entorno inmediato usando IA, ya sea para amistad, intereses comunes o networking profesional.',
        title2: 'Experimenta la magia de las conexiones instantáneas.',
        subtitle2: '¡Próximamente disponible en tiendas!',
        subtitle: '¿Lock?, ¿No? Ok. Nock',
        misionTitle: "Expandiendo tu identidad digital",
        misionDescription: "Nuestro enfoque no es el de una aplicación de citas, sino el de una herramienta que amplifica y comparte tu identidad digital para construir redes de valor, crecimiento de seguidores y abrir oportunidades de conexiones significativas en el mundo a tu alrededor.",
        intro: 'Empieza tu aventura de conexiones instantáneas',
        discoveryTitle: "Conectarte es muy fácil",
        discoveryDescription: "Descubre personas en tiempo real dentro de 1 km y usa nuestra IA para conectar con quienes comparten tus intereses.",
        chatTitle: "Rompe el hielo con un 'Nock Nock'",
        chatDescription: "Envía invitaciones para conectar y abre la puerta a tus redes sociales y mensajes privados que duran 10 min, solo si ambos están cerca.",
        findTitle: "Chat abierto en tiempo real",
        findDescription: "Intercambia mensajes al instante con usuarios cercanos, fomentando charlas dinámicas e interactivas que enriquecen y facilitan tu experiencia local.",
        learnTitle: "Conoce más",
        learnDescription: "Descubre todo lo que necesitas saber.",
        learnButton: "Explorar preguntas frecuentes",
        contactTitle: "Contáctanos",
        contactDescription: "¡Apreciamos mucho tu opinión! Siéntete libre de escribirnos."
      },
      download: {
        soon: 'Próximamente disponible en'
      },
      faqs: {
        subtitle: 'Por favor escríbenos si no encuentras la respuesta a tu pregunta a',
        questions: [{
          question: "¿Qué es 'Nock' y cómo funciona?",
          answer:
            "Nock es una aplicación móvil social que te conecta con personas cercanas. Con Nock, puedes descubrir y conectar con personas afines y expandir tu red social tanto personal como profesionalmente. Nock se enfoca en construir relaciones significativas y fomentar la interacción cara a cara en eventos y situaciones en tiempo real. Nuestra plataforma innovadora utiliza la geolocalización y tecnologías de cercanía para mostrarte oportunidades relevantes cerca de ti, ayudándote a conectar con otros usuarios mientras participas en experiencias en la vida real. Únete a la comunidad Nock y amplía tu círculo de contactos de manera auténtica y genuina.",
          open: true,
        },
        {
          question: "¿Es una aplicación para citas?",
          answer:
            "Nock no es una aplicación para citas, sino una herramienta para forjar conexiones oportunas y valiosas, ya sea para amistad, intereses compartidos o networking profesional. La experiencia de Nock es directa y significativa, permitiendo a los usuarios llevar su presencia social a un nivel más personal y contextual.",
          open: true,
        },
        {
          question: "¿Qué es el 'Radar' y cómo funciona?",
          answer:
            "El 'Radar' es una funcionalidad clave de Nock, diseñada para conectar a los usuarios con personas que se encuentran en su entorno inmediato. Utiliza tecnologías de geolocalización (GPS) y cercanía, como Bluetooth y redes Wi-Fi, para determinar la posición de los usuarios y permitirles compartir su perfil dentro de un radio máximo de 1 km. El Radar también utiliza IA para mostrar porcentajes de compatibilidad basados en intereses comunes.",
          open: true,
        },
        {
          question: "¿Cómo puedo usar la función 'Nock Nock'?",
          answer:
            "Simplemente enciende el Radar, encuentra a un usuario con el que te gustaría conectar, envíale una invitación 'Nock Nock' y, si es aceptada, podrás enviar mensajes privados y acceder a sus redes sociales.",
          open: false,
        },
        {
          question: "¿Qué hace único el 'Chat Abierto'?",
          answer:
            "'Chat Abierto' es nuestra dinámica sala de chat público donde cada mensaje dura solo 30 minutos. Mantiene la conversación animada, espontánea y garantiza una experiencia de chat fresca y en tiempo real.",
          open: false,
        },
        {
          question: "¿Nock está disponible tanto para iOS como para Android?",
          answer:
            "Actualmente, 'Nock' solo está disponible para descargar en las plataformas iOS.",
          open: false,
        },
        {
          question: "¿Puedo personalizar quién puede ver mi información?",
          answer:
            "¡Por supuesto! 'Nock' te permite controlar si tus enlaces de redes sociales son públicos o solo para tus Nocks, decidiendo quién puede conectar contigo. Puedes hacerlo en tu perfil o en el perfil del usuario.",
          open: false,
        },
        {
          question: "¿Los mensajes de 30 minutos en el 'Chat Abierto' se eliminan permanentemente?",
          answer:
            "Sí, los mensajes en 'Chat Abierto' desaparecen automáticamente después de 30 minutos, brindando una experiencia de chat única e instantánea.",
          open: false,
        },
        {
          question: "¿Qué tipo de información puedo compartir en mi perfil?",
          answer:
            "Puedes compartir tus enlaces preferidos de redes sociales, foto de perfil, nombre, descripción personal y otros intereses en tu perfil, dando a otros un vistazo a tu presencia en línea.",
          open: false,
        },
        {
          question: "¿Es 'Nock' gratuito para descargar y usar?",
          answer:
            "Sí, 'Nock' es gratuito para descargar y usar. Algunas funciones premium pueden estar disponibles mediante compras dentro de la aplicación.",
          open: false,
        },
        {
          question: "¿Qué tan seguros son los mensajes privados en 'Nock'?",
          answer:
            "Priorizamos la seguridad del usuario. Los mensajes privados en 'Nock' están cifrados para garantizar una experiencia de comunicación segura y agradable.",
          open: false,
        },
        {
          question: "¿Cómo puedo eliminar mi perfil?",
          answer:
            "Puedes eliminar tu perfil yendo a la configuración de tu cuenta y seleccionando 'Eliminar cuenta'. Todos tus datos personales se eliminarán permanentemente de nuestros servidores en un plazo de 30 días, aunque puede tardar hasta seis meses en completarse debido a procesos de respaldo y recuperación.",
          open: false,
        },
        {
          question: "¿Puedo bloquear o reportar usuarios?",
          answer:
            "Sí, puedes bloquear o reportar usuarios que consideres inapropiados o con los que no desees conectar. Esto se puede hacer desde su perfil o la pantalla de chat.",
          open: false,
        },
        {
          question: "¿Cómo sé si alguien está cerca de mí?",
          answer:
            "La función de Radar te notificará cuando alguien con intereses comunes esté dentro de un radio de 1 km de tu ubicación. Puedes ver su perfil y porcentaje de compatibilidad.",
          open: false,
        },
        {
          question: "¿Puedo cambiar mis intereses y preferencias?",
          answer:
            "Sí, puedes actualizar tus intereses y preferencias en cualquier momento en la configuración de tu perfil para mejorar las sugerencias de conexión.",
          open: false,
        },
        {
          question: "¿Cómo funciona el porcentaje de compatibilidad?",
          answer:
            "Nuestra IA analiza los detalles de tu perfil, como intereses, pasatiempos y descripción personal, para generar un porcentaje de compatibilidad con otros usuarios.",
          open: false,
        },
        {
          question: "¿Qué es la funcionalidad 'Favorito'?",
          answer:
            "La funcionalidad 'Favorito' te permite marcar perfiles importantes para que aparezcan primero en tu lista de Radar y Nocks.",
          open: false,
        },
        {
          question: "¿Qué sucede si ignoro una invitación 'Nock Nock'?",
          answer:
            "Si ignoras una invitación 'Nock Nock', esta expirará después de 24 horas y el remitente no será notificado.",
          open: false,
        },
        {
          question: "¿Cómo configuro mi perfil?",
          answer:
            "Configurar tu perfil es fácil. Solo ingresa tu nombre, foto y agrega tus enlaces de redes sociales en la sección de perfil de la aplicación.",
          open: false,
        },
        {
          question: "¿Puedo usar 'Nock' para conectar con fines profesionales?",
          answer:
            "Sí, 'Nock' está diseñado para ayudarte a conectar tanto para el networking personal como profesional, haciéndolo versátil para diversas necesidades de conexión.",
          open: false,
        },
        {
          question: "¿Qué hago si encuentro problemas técnicos?",
          answer:
            "Si encuentras algún problema técnico, contacta a nuestro equipo de soporte a través de la aplicación o por correo electrónico, y te asistiremos de inmediato.",
          open: false,
        },
        ],
      },
      terms: {
        text: 'Terms & Conditions of Service',

      },
      privacy: {
        text: 'Privacy Policy',
      },
      eula: {
        text: 'End-User License Agreement',
      },
      cookie: {
        text: 'Cookie Policy',
      },
      delete: {
        title: 'Eliminación de tu cuenta',
        description1: 'Los usuarios pueden eliminar sus cuentas en cualquier momento desde la aplicación móvil, lo que resultará en la eliminación permanente de su perfil, toda la información asociada, metadatos, Nocks y mensajes. Esta acción es irreversible, y todos los datos del usuario serán eliminados de nuestros sistemas.',
        description2: 'Nock hará todo lo posible para eliminar la información del usuario inmediatamente después de la eliminación de la cuenta. Sin embargo, debido a problemas operativos, puede haber retrasos, y podría tomar hasta seis meses eliminar todos los datos por completo. Se asegura a los usuarios que su información será completamente borrada tan pronto como sea posible dentro de este periodo.',
        titleSteps: 'Pasos para eliminar tu cuenta',
        step1: 'Abre la aplicación Nock y ve a "Configuración" en el menú desplegable',
        step2: 'Toca en "Cuenta" en el menú de configuración',
        step3: 'Toca el botón "Eliminar cuenta" en la parte inferior de la pantalla',
        step4: 'Toca el botón "Eliminar por siempre" en el diálogo de confirmación',
      }
    }
  },
};

messages['en-US'] = messages.en;
messages['es-CO'] = messages.es;
messages['es-ES'] = messages.es;
messages['es-MX'] = messages.es;
console.log(navigator.language);
const i18n = createI18n({
  locale: navigator.language?.split("-")[0] ?? "en",
  legacy: false,
  fallbackLocale: 'en',
  messages,
});

export default i18n;
