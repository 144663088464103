<template>
  <div class="bg-white max-w-screen-2xl w-full max-w-screen-xl mx-auto py-10">
    <div
      class="lg:ml-40 lg:mr-40 md:ml-5 md:mr-5 ml-5 mr-5 rounded bg-white text-justify"
    >
      <h1 class="text-center text-xl font-bold mb-10 pt-10">
        {{ $t("views.terms.text") }}
      </h1>
      <div
        class="terms-container lg:ml-40 lg:mr-40 md:ml-5 md:mr-5 ml-5 mr-5 pb-10 text-xs"
      >
        <h1>General Terms</h1>
        <p>
          By accessing and opening an account in Nock App LLC, you confirm that
          you are in agreement with and bound by the terms of service contained
          in the Terms & Conditions outlined below and with the
          <router-link class="text-black font-bold" to="/eula"
            >End-User License Agreement.</router-link
          >
          These terms apply to the entire website, mobile application and any
          email or other type of communication between you and Nock App LLC.
        </p>

        <p>
          Under no circumstances shall Nock App LLC team be liable for any
          direct, indirect, special, incidental or consequential damages,
          including, but not limited to, loss of data or profit, arising out of
          the use, or the inability to use, the materials on this site, even if
          Nock App LLC team or an authorized representative has been advised of
          the possibility of such damages. If your use of materials from this
          site results in the need for servicing, repair or correction of
          equipment or data, you assume any costs thereof.
        </p>
        <p>
          Nock App LLC will not be responsible for any outcome that may occur
          during the course of usage of our resources. We reserve the rights to
          change prices and revise the resources usage policy in any moment.
        </p>

        <h1>License</h1>
        <p>
          Nock App LLC grants you a revocable, non-exclusive, non-transferable,
          limited license to download, install and use the app strictly in
          accordance with the terms of this Agreement.
        </p>
        <p>
          These Terms & Conditions are a contract between you and Nock App LLC
          (referred to in these Terms & Conditions as "Nock", "us", "we" or
          "our"), the provider of the Nock website and the services accessible
          from the Nock Mobile App (which are collectively referred to in these
          Terms & Conditions as the "Nock Service").
        </p>
        <p>
          You are agreeing to be bound by these Terms & Conditions. If you do
          not agree to these Terms & Conditions, please do not use the Nock App
          LLC Service. In these Terms & Conditions, "you" refers both to you as
          an individual and to the entity you represent. If you violate any of
          these Terms & Conditions, we reserve the right to cancel your account
          or block access to your account without notice.
        </p>

        <h1>Definitions and key terms</h1>
        <p>
          To help explain things as clearly as possible in this Terms &
          Conditions, every time any of these terms are referenced, are strictly
          defined as:
        </p>
        <ul>
          <li>
            Cookie: small amount of data generated by a website and saved by
            your web browser. It is used to identify your browser, provide
            analytics, remember information about you such as your language
            preference or login information.
          </li>
          <li>
            Company: when this policy mentions “Company,” “we,” “us,”, "Nock" or
            “our,” it refers to Nock App LLC, that is responsible for your
            information under this Terms & Conditions.
          </li>
          <li>
            Country: where Nock App LLC or the owners/founders of Nock App LLC
            are based, in this case is United States Of America
          </li>
          <li>
            Device: any internet connected device such as a phone, tablet,
            computer or any other device that can be used to visit Nock App LLC
            and use the services.
          </li>
          <li>
            Service: refers to the service provided by Nock App LLC as described
            in the relative terms (if available) and on this platform.
          </li>
          <li>
            Third-party service: refers to advertisers, contest sponsors,
            promotional and marketing partners, and others who provide our
            content or whose products or services we think may interest you.
          </li>
          <li>
            Website: Nock App LLC's site, which can be accessed via this
            URL:https://getnock.com/
          </li>
          <li>
            App: Nock App LLC's mobile application, which can be download from
            Apple App Store.
          </li>
          <li>
            You: a person or entity that is registered with Nock App LLC to use
            the Services.
          </li>
        </ul>
        <h1>User Profile Information</h1>
        <h2>Collection of Profile Information</h2>
        <p>
          When you create an account with Nock, we collect various types of
          personal information to build your user profile. This includes your
          name, profile photo, personal description, and links to your social
          media accounts. This information helps other users identify and
          connect with you, enhancing your experience on our platform.
        </p>
        <h2>Usage of Profile Information</h2>
        <p>
          The information you provide in your profile, such as your name,
          profile photo, description, and social media links, is visible to
          other users on Nock. This visibility facilitates connections and
          interactions with other users who may share similar interests or
          backgrounds. Your profile acts as a digital business card, allowing
          others to learn more about you at a glance.
        </p>
        <h2>Privacy and Security</h2>
        <p>
          Your profile information is securely stored and protected with
          advanced encryption technologies. We are committed to safeguarding
          your personal data and ensuring that your privacy is maintained. While
          your profile information is visible to other users to facilitate
          connections, sensitive information such as your contact details is
          kept private unless you choose to share it directly.
        </p>
        <h2>Modification and Deletion of Profile Information</h2>
        <p>
          You have the right to modify or delete your profile information at any
          time through your account settings. Updates to your profile may take
          up to 24 hours to be fully reflected across the platform. If you
          choose to delete your profile, all your personal data, including
          connections and messages, will be permanently removed from our servers
          within 30 days. However, due to backup and data recovery processes, it
          may take up to six months for all residual data to be completely
          erased.
        </p>
        <h1>Radar Functionality</h1>
        <h2>Operation Using Geolocation Technologies</h2>
        <p>
          The Radar functionality of Nock operates using geolocation
          technologies, Wi-Fi networks, and Bluetooth. This feature allows users
          to discover and connect with others within a maximum range of 1
          kilometer. By enabling the Radar, users consent to the application
          utilizing these technologies to detect nearby users and facilitate
          connections based on proximity.
        </p>
        <h2>Disclaimer of Responsibility</h2>
        <p>
          Nock is not responsible for the individuals you contact and share
          information with (such as profile picture, name, social media links,
          user description, and city) when you use the Radar feature. Users
          should exercise caution and good judgment when interacting with others
          and sharing personal information through the Radar.
        </p>
        <h2>User Control and Reporting Options</h2>
        <p>
          The Radar feature is activated at the user's discretion. Users have
          the ability to turn the Radar on or off as they wish. Additionally,
          users can block and report unwanted users to prevent them from
          interacting with or locating them through the Radar. This ensures a
          safer and more controlled user experience.
        </p>
        <h2>Misuse and Account Blocking</h2>
        <p>
          Misuse of the Radar feature, including but not limited to providing
          false profile information, harassing other users, or impersonating
          others, will result in the blocking of the offending account. Nock is
          committed to maintaining a respectful and secure environment for all
          users, and such behavior will not be tolerated.
        </p>
        <h2>Geolocation Privacy</h2>
        <p>
          Your geolocation data is never exposed in a way that allows other
          users to access it without your permission. The Radar feature only
          shows your approximate distance to other users without revealing your
          exact location. Additionally, access to your account and data is
          protected through the authentication information you provide (such as
          your mobile number and password), ensuring your privacy and security.
        </p>
        <h1>Compatibility Evaluation Using Artificial Intelligence (AI)</h1>
        <h2>Authorization to Use Profile Information and Behavior Data</h2>
        <p>
          By creating an account, filling out your profile information, and
          configuring the Radar feature (including a private description of
          yourself and your preferences for Radar prioritization), you authorize
          Nock to use this information along with your behavior and preferences
          within the app. This data is utilized by our AI algorithm to calculate
          a compatibility score and user metadata. Additionally, we may share
          anonymized data with third parties solely for the purpose of enhancing
          our compatibility calculations and improving the user experience. This
          information is used to indicate the percentage of compatibility with
          other users found through the Radar.
        </p>
        <h2>Privacy of Algorithm and Compatibility Calculation Method</h2>
        <p>
          The algorithm and methods used to calculate compatibility scores are
          proprietary to Nock and will not be disclosed to any third party or
          user under any circumstances. If requested, users can obtain the data
          they have provided to us by contacting our support team via email.
          However, the proprietary algorithm used to compare users and generate
          compatibility scores will remain confidential and will not be shared.
        </p>
        <h2>Disclaimer of Responsibility for Compatibility Recommendations</h2>
        <p>
          Nock is not responsible for the recommendations or compatibility
          percentages provided by the AI, nor for the outcomes of any
          interactions or relationships that result from these recommendations.
          The compatibility scores are purely calculated based on the data
          entered by users, and Nock cannot be held accountable for the
          potential results of these interactions. Users are encouraged to use
          their own judgment and caution when engaging with other users based on
          compatibility scores.
        </p>
        <h1>Use of Open Chat and Private Chats</h1>
        <h2>Lifespan of Messages</h2>
        <p>
          Nock provides two types of chat functionalities: Open Chat and Private
          Chats. Messages in Open Chat have a lifespan of 30 minutes and are
          permanently deleted after this period. Private Chats between "Tus
          Nocks" last for 10 minutes and are also permanently deleted after this
          timeframe. By using these chat features, you acknowledge and accept
          that messages will be deleted and cannot be recovered after their
          respective durations.
        </p>
        <h2>Disclaimer of Responsibility for User Conversations</h2>
        <p>
          Nock is not responsible for the content of the conversations held
          between users in either Open Chat or Private Chats. Users are solely
          responsible for the messages they send and receive. Nock does not
          monitor these conversations in real-time but reserves the right to
          process message content to ensure compliance with our community
          guidelines and terms of use.
        </p>
        <h2>Definitive Deletion of Information</h2>
        <p>
          All messages sent through Nock’s chat features are deleted permanently
          after their designated lifespan (30 minutes for Open Chat and 10
          minutes for Private Chats). This deletion is irreversible, and Nock
          does not retain any copies of the deleted messages. By using these
          features, you accept that your messages will be handled accordingly.
        </p>
        <h2>Right to Monitor and Ensure Proper Use</h2>
        <p>
          While Nock does not actively monitor user conversations, we reserve
          the right to process and review message content to ensure the proper
          use of our chat features and to enforce our community guidelines. This
          may include, but is not limited to, scanning for offensive language,
          harassment, or other inappropriate behavior.
        </p>
        <h2>Consequences of Misuse</h2>
        <p>
          Misuse of the chat features, including but not limited to the use of
          offensive language, harassment, impersonation, or any form of abuse,
          may result in the temporary or permanent blocking of the offending
          account. Nock is committed to maintaining a respectful and safe
          environment for all users, and any behavior that violates our terms
          and conditions will not be tolerated.
        </p>
        <h1>Use of Nock Nock Invitations</h1>
        <h2>Functionality and Lifespan of Invitations</h2>
        <p>
          Nock Nock invitations allow users to invite others to connect. These
          invitations remain active for 24 hours. If not accepted within this
          period, the invitations are permanently deleted. Once an invitation is
          accepted, both users become part of "Tus Nocks," a personal network of
          contacts. Users cannot reject invitations; they can only accept them
          or wait for them to expire.
        </p>
        <h2>Disclaimer of Responsibility for Interactions</h2>
        <p>
          Nock is not responsible for the interactions that occur as a result of
          Nock Nock invitations. Users are solely responsible for the
          invitations they send and the connections they make. Nock does not
          monitor these interactions in real-time but reserves the right to
          process invitation content to ensure compliance with our community
          guidelines and terms of use.
        </p>
        <h2>Definitive Deletion of Information</h2>
        <p>
          Nock Nock invitations are automatically deleted 24 hours after being
          sent if no action is taken. Accepted invitations are recorded until
          the relevant action is taken. Once the 24-hour period elapses, the
          invitation is permanently deleted and cannot be recovered. By using
          the Nock Nock feature, you accept that invitations will be handled
          accordingly.
        </p>
        <h2>Right to Monitor and Ensure Proper Use</h2>
        <p>
          While Nock does not actively monitor the content of invitations, we
          reserve the right to process and review invitations to ensure the
          proper use of our features and to enforce our community guidelines.
          This may include, but is not limited to, scanning for offensive
          language, harassment, or other inappropriate behavior.
        </p>
        <h2>Consequences of Misuse</h2>
        <p>
          Misuse of the Nock Nock invitation feature, including but not limited
          to sending offensive or inappropriate invitations, harassment, or any
          form of abuse, may result in the temporary or permanent blocking of
          the offending account. Nock is committed to maintaining a respectful
          and safe environment for all users, and any behavior that violates our
          terms and conditions will not be tolerated.
        </p>
        <h1>Account Deletion and Data Removal</h1>
        <p>
          Users can delete their accounts at any time from the app, which will
          result in the permanent deletion of their profile, all associated
          information, metadata, Nocks, and messages. This action is
          irreversible, and all user data will be removed from our systems.
        </p>
        <p>
          Nock will make every effort to delete user information immediately
          upon account deletion. However, due to operational issues, there may
          be delays, and it could take up to six months to fully remove all
          data. Users are assured that their information will be completely
          erased as soon as possible within this timeframe.
        </p>
        <h1>Restrictions</h1>
        <p>You agree not to, and you will not permit others to:</p>
        <ul>
          <li>
            License, sell, rent, lease, assign, distribute, transmit, host,
            outsource, disclose or otherwise commercially exploit the app or
            make the platform available to any third party.
          </li>
          <li>
            Modify, make derivative works of, disassemble, decrypt, reverse
            compile or reverse engineer any part of the app.
          </li>
          <li>
            Remove, alter or obscure any proprietary notice (including any
            notice of copyright or trademark) of Nock or its affiliates,
            partners, suppliers or the licensors of the app.
          </li>
        </ul>
        <h1>Return and Refund Policy</h1>
        <p>
          Thanks for registering at Nock. We appreciate the fact that you like
          to use our app. We also want to make sure you have a rewarding
          experience while you’re exploring, evaluating, and using our products.
        </p>
        <p>
          As with any application experience, there are terms and conditions
          that apply to transactions at Nock. We’ll be as brief as our attorneys
          will allow. The main thing to remember is that by placing an order or
          making a purchase at Nock, you agree to the terms along with Nock's
          Privacy Policy.
        </p>
        <p>
          If, for any reason, You are not completely satisfied with any good or
          service that we provide, don't hesitate to contact us and we will
          discuss any of the issues you are going through with our product.
        </p>

        <h1>Your Suggestions</h1>
        <p>
          Any feedback, comments, ideas, improvements or suggestions
          (collectively, "Suggestions") provided by you to Nock with respect to
          the app shall remain the sole and exclusive property of Nock.
        </p>
        <p>
          Nock shall be free to use, copy, modify, publish, or redistribute the
          Suggestions for any purpose and in any way without any credit or any
          compensation to you.
        </p>

        <h1>Your Consent</h1>
        <p>
          We've updated our Terms & Conditions to provide you with complete
          transparency into what is being set when you visit our site and how
          it's being used. By using our app, registering an account, or making a
          purchase, you hereby consent to our Terms & Conditions.
        </p>

        <h1>Links to Other Websites</h1>
        <p>
          This Terms & Conditions applies only to the Services. The Services may
          contain links to other websites not operated or controlled by Nock. We
          are not responsible for the content, accuracy or opinions expressed in
          such websites, and such websites are not investigated, monitored or
          checked for accuracy or completeness by us. Please remember that when
          you use a link to go from the Services to another website, our Terms &
          Conditions are no longer in effect. Your browsing and interaction on
          any other website, including those that have a link on our platform,
          is subject to that website’s own rules and policies. Such third
          parties may use their own cookies or other methods to collect
          information about you.
        </p>

        <h1>Cookies</h1>
        <p>
          Nock uses "Cookies" to identify the areas of our website that you have
          visited. A Cookie is a small piece of data stored on your computer or
          mobile device by your web browser. We use Cookies to enhance the
          performance and functionality of our app but are non-essential to
          their use. However, without these cookies, certain functionality like
          videos may become unavailable or you would be required to enter your
          login details every time you visit the app as we would not be able to
          remember that you had logged in previously. Most web browsers can be
          set to disable the use of Cookies. However, if you disable Cookies,
          you may not be able to access functionality on our website correctly
          or at all. We never place Personally Identifiable Information in
          Cookies.
        </p>

        <h1>Changes To Our Terms & Conditions</h1>
        <p>
          You acknowledge and agree that Nock may stop (permanently or
          temporarily) providing the Service (or any features within the
          Service) to you or to users generally at sole discretion, without
          prior notice to you. You may stop using the Service at any time. You
          do not need to specifically inform Nock when you stop using the
          Service. You acknowledge and agree that if Nock disables access to
          your account, you may be prevented from accessing the Service, your
          account details or any files or other materials which is contained in
          your account.
        </p>
        <p>
          If we decide to change our Terms & Conditions, we will post those
          changes on this page, and/or update the Terms & Conditions
          modification date below.
        </p>

        <h1>Modifications to Our app</h1>
        <p>
          Nock reserves the right to modify, suspend or discontinue, temporarily
          or permanently, the app or any service to which it connects, with or
          without notice and without liability to you.
        </p>

        <h1>Updates to Our app</h1>
        <p>
          Nock may from time to time provide enhancements or improvements to the
          features/ functionality of the app, which may include patches, bug
          fixes, updates, upgrades and other modifications ("Updates").
        </p>
        <p>
          Updates may modify or delete certain features and/or functionalities
          of the app. You agree that Nock has no obligation to (i) provide any
          Updates, or (ii) continue to provide or enable any particular features
          and/or functionalities of the app to you.
        </p>
        <p>
          You further agree that all Updates will be (i) deemed to constitute an
          integral part of the app, and (ii) subject to the terms and conditions
          of this Agreement.
        </p>

        <h1>Third-Party Services</h1>
        <p>
          We may display, include or make available third-party content
          (including data, information, applications and other products
          services) or provide links to third-party websites or services
          ("Third- Party Services").
        </p>
        <p>
          You acknowledge and agree that Nock shall not be responsible for any
          Third-Party Services, including their accuracy, completeness,
          timeliness, validity, copyright compliance, legality, decency, quality
          or any other aspect thereof. Nock does not assume and shall not have
          any liability or responsibility to you or any other person or entity
          for any Third-Party Services.
        </p>
        <p>
          Third-Party Services and links thereto are provided solely as a
          convenience to you and you access and use them entirely at your own
          risk and subject to such third parties' terms and conditions.
        </p>

        <h1>Term and Termination</h1>
        <p>
          This Agreement shall remain in effect until terminated by you or Nock.
        </p>
        <p>
          Nock may, in its sole discretion, at any time and for any or no
          reason, suspend or terminate this Agreement with or without prior
          notice.
        </p>
        <p>
          This Agreement will terminate immediately, without prior notice from
          Nock, in the event that you fail to comply with any provision of this
          Agreement. You may also terminate this Agreement by deleting the app
          and all copies thereof from your computer.
        </p>
        <p>
          Upon termination of this Agreement, you shall cease all use of the app
          and delete all copies of the app from your computer.
        </p>
        <p>
          Termination of this Agreement will not limit any of Nock's rights or
          remedies at law or in equity in case of breach by you (during the term
          of this Agreement) of any of your obligations under the present
          Agreement.
        </p>

        <h1>Copyright Infringement Notice</h1>
        <p>
          If you are a copyright owner or such owner’s agent and believe any
          material on our app constitutes an infringement on your copyright,
          please contact us setting forth the following information: (a) a
          physical or electronic signature of the copyright owner or a person
          authorized to act on his behalf; (b) identification of the material
          that is claimed to be infringing; (c) your contact information,
          including your address, telephone number, and an email; (d) a
          statement by you that you have a good faith belief that use of the
          material is not authorized by the copyright owners; and (e) the a
          statement that the information in the notification is accurate, and,
          under penalty of perjury you are authorized to act on behalf of the
          owner.
        </p>

        <h1>Indemnification</h1>
        <p>
          You agree to indemnify and hold Nock and its parents, subsidiaries,
          affiliates, officers, employees, agents, partners and licensors (if
          any) harmless from any claim or demand, including reasonable
          attorneys' fees, due to or arising out of your: (a) use of the app;
          (b) violation of this Agreement or any law or regulation; or (c)
          violation of any right of a third party.
        </p>

        <h1>No Warranties</h1>
        <p>
          The app is provided to you "AS IS" and "AS AVAILABLE" and with all
          faults and defects without warranty of any kind. To the maximum extent
          permitted under applicable law, Nock, on its own behalf and on behalf
          of its affiliates and its and their respective licensors and service
          providers, expressly disclaims all warranties, whether express,
          implied, statutory or otherwise, with respect to the app, including
          all implied warranties of merchantability, fitness for a particular
          purpose, title and non-infringement, and warranties that may arise out
          of course of dealing, course of performance, usage or trade practice.
          Without limitation to the foregoing, Nock provides no warranty or
          undertaking, and makes no representation of any kind that the app will
          meet your requirements, achieve any intended results, be compatible or
          work with any other software, , systems or services, operate without
          interruption, meet any performance or reliability standards or be
          error free or that any errors or defects can or will be corrected.
        </p>
        <p>
          Without limiting the foregoing, neither Nock nor any Nock's provider
          makes any representation or warranty of any kind, express or implied:
          (i) as to the operation or availability of the app, or the
          information, content, and materials or products included thereon; (ii)
          that the app will be uninterrupted or error-free; (iii) as to the
          accuracy, reliability, or currency of any information or content
          provided through the app; or (iv) that the app, its servers, the
          content, or e-mails sent from or on behalf of Nock are free of
          viruses, scripts, trojan horses, worms, malware, timebombs or other
          harmful components.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of or limitations on
          implied warranties or the limitations on the applicable statutory
          rights of a consumer, so some or all of the above exclusions and
          limitations may not apply to you.
        </p>

        <h1>Limitation of Liability</h1>
        <p>
          Notwithstanding any damages that you might incur, the entire liability
          of Nock and any of its suppliers under any provision of this Agreement
          and your exclusive remedy for all of the foregoing shall be limited to
          the amount actually paid by you for the app.
        </p>
        <p>
          To the maximum extent permitted by applicable law, in no event shall
          Nock or its suppliers be liable for any special, incidental, indirect,
          or consequential damages whatsoever (including, but not limited to,
          damages for loss of profits, for loss of data or other information,
          for business interruption, for personal injury, for loss of privacy
          arising out of or in any way related to the use of or inability to use
          the app, third-party software and/or third-party hardware used with
          the app, or otherwise in connection with any provision of this
          Agreement), even if Nock or any supplier has been advised of the
          possibility of such damages and even if the remedy fails of its
          essential purpose.
        </p>
        <p>
          Some states/jurisdictions do not allow the exclusion or limitation of
          incidental or consequential damages, so the above limitation or
          exclusion may not apply to you.
        </p>

        <h1>Severability</h1>
        <p>
          If any provision of this Agreement is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </p>
        <p>
          This Agreement, together with the Privacy Policy and any other legal
          notices published by Nock on the Services, shall constitute the entire
          agreement between you and Nock concerning the Services. If any
          provision of this Agreement is deemed invalid by a court of competent
          jurisdiction, the invalidity of such provision shall not affect the
          validity of the remaining provisions of this Agreement, which shall
          remain in full force and effect. No waiver of any term of this
          Agreement shall be deemed a further or continuing waiver of such term
          or any other term, and Nock's failure to assert any right or provision
          under this Agreement shall not constitute a waiver of such right or
          provision. YOU AND Nock AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF
          OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE
          CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS
          PERMANENTLY BARRED.
        </p>

        <h1>Waiver</h1>
        <p>
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under this Agreement shall not
          effect a party's ability to exercise such right or require such
          performance at any time thereafter nor shall be the waiver of a breach
          constitute waiver of any subsequent breach.
        </p>
        <p>
          o failure to exercise, and no delay in exercising, on the part of
          either party, any right or any power under this Agreement shall
          operate as a waiver of that right or power. Nor shall any single or
          partial exercise of any right or power under this Agreement preclude
          further exercise of that or any other right granted herein. In the
          event of a conflict between this Agreement and any applicable purchase
          or other terms, the terms of this Agreement shall govern.
        </p>

        <h1>Amendments to this Agreement</h1>
        <p>
          Nock reserves the right, at its sole discretion, to modify or replace
          this Agreement at any time. If a revision is material we will provide
          at least 30 days' notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our app after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you are no longer authorized to use Nock.
        </p>

        <h1>Entire Agreement</h1>
        <p>
          The Agreement constitutes the entire agreement between you and Nock
          regarding your use of the app and supersedes all prior and
          contemporaneous written or oral agreements between you and Nock.
        </p>
        <p>
          You may be subject to additional terms and conditions that apply when
          you use or purchase other Nock's services, which Nock will provide to
          you at the time of such use or purchase.
        </p>

        <h1>Updates to Our Terms</h1>
        <p>
          We may change our Service and policies, and we may need to make
          changes to these Terms so that they accurately reflect our Service and
          policies. Unless otherwise required by law, we will notify you (for
          example, through our Service) before we make changes to these Terms
          and give you an opportunity to review them before they go into effect.
          Then, if you continue to use the Service, you will be bound by the
          updated Terms. If you do not want to agree to these or any updated
          Terms, you can delete your account.
        </p>

        <h1>Intellectual Property</h1>
        <p>
          The app and its entire contents, features and functionality (including
          but not limited to all information, software, text, displays, images,
          video and audio, and the design, selection and arrangement thereof),
          are owned by Nock, its licensors or other providers of such material
          and are protected by and international copyright, trademark, patent,
          trade secret and other intellectual property or proprietary rights
          laws. The material may not be copied, modified, reproduced, downloaded
          or distributed in any way, in whole or in part, without the express
          prior written permission of Nock, unless and except as is expressly
          provided in these Terms & Conditions. Any unauthorized use of the
          material is prohibited.
        </p>

        <h1>Agreement to Arbitrate</h1>
        <p>
          This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A
          DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF
          REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR Nock's INTELLECTUAL
          PROPERTY RIGHTS. The term “dispute” means any dispute, action, or
          other controversy between you and Nock concerning the Services or this
          agreement, whether in contract, warranty, tort, statute, regulation,
          ordinance, or any other legal or equitable basis. “Dispute” will be
          given the broadest possible meaning allowable under law.
        </p>

        <h1>Notice of Dispute</h1>
        <p>
          In the event of a dispute, you or Nock must give the other a Notice of
          Dispute, which is a written statement that sets forth the name,
          address, and contact information of the party giving it, the facts
          giving rise to the dispute, and the relief requested. You must send
          any Notice of Dispute via email to:
          <a
            href="/cdn-cgi/l/email-protection"
            class="__cf_email__"
            data-cfemail="1a7975746e7b796e5a7d7f6e7475797134797577"
            >[email&#160;protected]</a
          >. Nock will send any Notice of Dispute to you by mail to your address
          if we have it, or otherwise to your email address. You and Nock will
          attempt to resolve any dispute through informal negotiation within
          sixty (60) days from the date the Notice of Dispute is sent. After
          sixty (60) days, you or Nock may commence arbitration.
        </p>

        <h1>Binding Arbitration</h1>
        <p>
          If you and Nock don’t resolve any dispute by informal negotiation, any
          other effort to resolve the dispute will be conducted exclusively by
          binding arbitration as described in this section. You are giving up
          the right to litigate (or participate in as a party or class member)
          all disputes in court before a judge or jury. The dispute shall be
          settled by binding arbitration in accordance with the commercial
          arbitration rules of the American Arbitration Association. Either
          party may seek any interim or preliminary injunctive relief from any
          court of competent jurisdiction, as necessary to protect the party’s
          rights or property pending the completion of arbitration. Any and all
          legal, accounting, and other costs, fees, and expenses incurred by the
          prevailing party shall be borne by the non-prevailing party.
        </p>

        <h1>Submissions and Privacy</h1>
        <p>
          In the event that you submit or post any ideas, creative suggestions,
          designs, photographs, information, advertisements, data or proposals,
          including ideas for new or improved products, services, features,
          technologies or promotions, you expressly agree that such submissions
          will automatically be treated as non-confidential and non-proprietary
          and will become the sole property of Nock without any compensation or
          credit to you whatsoever. Nock and its affiliates shall have no
          obligations with respect to such submissions or posts and may use the
          ideas contained in such submissions or posts for any purposes in any
          medium in perpetuity, including, but not limited to, developing,
          manufacturing, and marketing products and services using such ideas.
        </p>

        <h1>Promotions</h1>
        <p>
          Nock may, from time to time, include contests, promotions,
          sweepstakes, or other activities (“Promotions”) that require you to
          submit material or information concerning yourself. Please note that
          all Promotions may be governed by separate rules that may contain
          certain eligibility requirements, such as restrictions as to age and
          geographic location. You are responsible to read all Promotions rules
          to determine whether or not you are eligible to participate. If you
          enter any Promotion, you agree to abide by and to comply with all
          Promotions Rules.
        </p>
        <p>
          Additional terms and conditions may apply to purchases of goods or
          services on or through the Services, which terms and conditions are
          made a part of this Agreement by this reference.
        </p>

        <h1>Typographical Errors</h1>
        <p>
          In the event a product and/or service is listed at an incorrect price
          or with incorrect information due to typographical error, we shall
          have the right to refuse or cancel any orders placed for the product
          and/or service listed at the incorrect price. We shall have the right
          to refuse or cancel any such order whether or not the order has been
          confirmed and your credit card charged. If your credit card has
          already been charged for the purchase and your order is canceled, we
          shall immediately issue a credit to your credit card account or other
          payment account in the amount of the charge.
        </p>

        <h1>Miscellaneous</h1>
        <p>
          If for any reason a court of competent jurisdiction finds any
          provision or portion of these Terms & Conditions to be unenforceable,
          the remainder of these Terms & Conditions will continue in full force
          and effect. Any waiver of any provision of these Terms & Conditions
          will be effective only if in writing and signed by an authorized
          representative of Nock. Nock will be entitled to injunctive or other
          equitable relief (without the obligations of posting any bond or
          surety) in the event of any breach or anticipatory breach by you. Nock
          operates and controls the Nock Service from its offices in . The
          Service is not intended for distribution to or use by any person or
          entity in any jurisdiction or country where such distribution or use
          would be contrary to law or regulation. Accordingly, those persons who
          choose to access the Nock Service from other locations do so on their
          own initiative and are solely responsible for compliance with local
          laws, if and to the extent local laws are applicable. These Terms &
          Conditions (which include and incorporate the Nock Privacy Policy)
          contains the entire understanding, and supersedes all prior
          understandings, between you and Nock concerning its subject matter,
          and cannot be changed or modified by you. The section headings used in
          this Agreement are for convenience only and will not be given any
          legal import.
        </p>

        <h1>Disclaimer</h1>
        <p>
          Nock is not responsible for any content, code or any other
          imprecision.
        </p>
        <p>Nock does not provide warranties or guarantees.</p>
        <p>
          In no event shall Nock be liable for any special, direct, indirect,
          consequential, or incidental damages or any damages whatsoever,
          whether in an action of contract, negligence or other tort, arising
          out of or in connection with the use of the Service or the contents of
          the Service. The Company reserves the right to make additions,
          deletions, or modifications to the contents on the Service at any time
          without prior notice.
        </p>
        <p>
          The Nock Service and its contents are provided "as is" and "as
          available" without any warranty or representations of any kind,
          whether express or implied. Nock is a distributor and not a publisher
          of the content supplied by third parties; as such, Nock exercises no
          editorial control over such content and makes no warranty or
          representation as to the accuracy, reliability or currency of any
          information, content, service or merchandise provided through or
          accessible via the Nock Service. Without limiting the foregoing, Nock
          specifically disclaims all warranties and representations in any
          content transmitted on or in connection with the Nock Service or on
          sites that may appear as links on the Nock Service, or in the products
          provided as a part of, or otherwise in connection with, the Nock
          Service, including without limitation any warranties of
          merchantability, fitness for a particular purpose or non-infringement
          of third party rights. No oral advice or written information given by
          Nock or any of its affiliates, employees, officers, directors, agents,
          or the like will create a warranty. Price and availability information
          is subject to change without notice. Without limiting the foregoing,
          Nock does not warrant that the Nock Service will be uninterrupted,
          uncorrupted, timely, or error-free.
        </p>
        <h1>Limited liability</h1>
        <p>
          Nock endeavours to update and/or supplement the content of the app on
          a regular basis. Despite our care and attention, content may be
          incomplete and/or incorrect.
        </p>
        <p>
          The materials offered on the app are offered without any form of
          guarantee or claim to their correctness. These materials can be
          changed at any time without prior notice from Nock.
        </p>
        <p>
          Particularly, all prices on the app are stated subject to typing and
          programming errors. No liability is assumed for the implications of
          such errors. No agreement is concluded on the basis of such errors.
        </p>
        <p>
          Nock shall not bear any liability for hyperlinks to websites or
          services of third parties included on the app. From our app, you can
          visit other websites by following hyperlinks to such external sites.
          While we strive to provide only quality links to useful and ethical
          websites, we have no control over the content and nature of these
          sites. These links to other websites do not imply a recommendation for
          all the content found on these sites. Site owners and content may
          change without notice and may occur before we have the opportunity to
          remove a link which may have gone ‘bad’.
        </p>
        <p>
          Please be also aware that when you leave our app, other sites may have
          different privacy policies and terms which are beyond our control.
          Please be sure to check the Privacy Policies of these sites as well as
          their "Terms of Service" before engaging in any business or uploading
          any information.
        </p>
        <h1>Links to Other Websites Disclaimer</h1>
        <p>
          This Disclaimer applies only to the Services. The Services may contain
          links to other websites not operated or controlled by Nock. We are not
          responsible for the content, accuracy or opinions expressed in such
          websites, and such websites are not investigated, monitored or checked
          for accuracy or completeness by us. Please remember that when you use
          a link to go from the Services to another website, our Privacy Policy
          is no longer in effect. Your browsing and interaction on any other
          website, including those that have a link on our platform, is subject
          to that website’s own rules and policies. Such third parties may use
          their own cookies or other methods to collect information about you.
          If You click on a third party link, You will be directed to that third
          party's site. We strongly advise You to review the Privacy Policy and
          Terms of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <h1>Errors and Omissions Disclaimer</h1>
        <p>
          Nock is not responsible for any content, code or any other
          imprecision.
        </p>
        <p>Nock does not provide warranties or guarantees.</p>
        <p>
          In no event shall Nock be liable for any special, direct, indirect,
          consequential, or incidental damages or any damages whatsoever,
          whether in an action of contract, negligence or other tort, arising
          out of or in connection with the use of the Service or the contents of
          the Service. Nock reserves the right to make additions, deletions, or
          modifications to the contents on the Service at any time without prior
          notice.
        </p>
        <h1>Affiliate Links Disclosure</h1>
        <p>
          Nock has affiliate links and in this section of the Disclaimer we will
          address how we use those affiliate links from other websites/companies
          and products. These “affiliate links” are specific URLs that contain
          the affiliate's ID or username.
        </p>
        <p>
          In compliance with the FTC guidelines, please assume the following
          about links and posts on this site:
        </p>
        <ul>
          <li>
            Any/all of the links on Nock are affiliate links of which we receive
            a small commission from sales of certain items, but the price is the
            same for you. As Nock has grown, so have costs associated with
            running and maintaining it, and affiliate links are a way we help
            offset these costs.
          </li>
          <li>
            If we post an affiliate link to a product, it is something that we
            personally use, support and would recommend without an affiliate
            link.
          </li>
          <li>
            Unless otherwise noted, all reviews are of items we have purchased
            and we are not paid or compensated in any way.
          </li>
        </ul>
        <h1>Advertising Disclosure</h1>
        <p>
          This app may contain third party advertisements and links to third
          party sites. Nock does not make any representation as to the accuracy
          or suitability of any of the information contained in those
          advertisements or sites and does not accept any responsibility or
          liability for the conduct or content of those advertisements and sites
          and the offerings made by the third parties.
        </p>
        <p>
          Advertising keeps Nock and many of the websites and services you use
          free of charge. We work hard to make sure that ads are safe,
          unobtrusive, and as relevant as possible.
        </p>
        <p>
          Third party advertisements and links to other sites where goods or
          services are advertised are not endorsements or recommendations by
          Nock of the third party sites, goods or services. Nock takes no
          responsibility for the content of any of the ads, promises made, or
          the quality/reliability of the products or services offered in all
          advertisements.
        </p>
        <h1>Your Consent</h1>
        <p>
          We've updated our Disclaimer to provide you with complete transparency
          into what is being set when you visit our site and how it's being
          used. By using our app, registering an account, or making a purchase,
          you hereby consent to our Disclaimer and agree to its terms.
        </p>

        <h1>Changes To Our Disclaimer</h1>
        <p>
          Should we update, amend or make any changes to this document so that
          they accurately reflect our Service and policies. Unless otherwise
          required by law, those changes will be prominently posted here. Then,
          if you continue to use the Service, you will be bound by the updated
          Disclaimer. If you do not want to agree to this or any updated
          Disclaimer, you can delete your account.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "terms-conditions-view",
  data() {
    return {};
  },
};
</script>

<style scoped>
.terms-container h1 {
  font-size: 1.1rem;
  line-height: 1.2;
  text-align: left;
  font-weight: 600;
  color: black;
  margin-top: 2rem;
  margin-bottom: 1.3rem;
}

.terms-container p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.terms-container ul li {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
