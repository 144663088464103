<template>
  <header class="bg-white w-full mx-auto top-0 fixed">
    <div class="w-full max-w-screen-lg mx-auto p-2 without-pr center-header">
      <img
        src="@/assets/nock_iso_redblack.png"
        class="h-5 md:h-5"
      />
    </div>
  </header>
</template>

<script>
export default {
  name: "header-component",
  data() {
    return {
     
    };
  },
 
};
</script>

<style scoped>
.center-header {
  vertical-align: middle;
  text-align: center;
}
</style>
