<!-- Home.vue -->
<template>
  <div class="bg-black items-center w-full">
    <div class="">
      <!-- First Section -->
      <section class="bg-black text-white py-10 max-w-screen-xl mx-auto">
        <div
          class="container mx-auto flex flex-col md:flex-row items-center sm:py-10"
        >
          <!-- Left Column -->
          <div class="md:w-1/2 md:pr-8 px-5 md:pb-20">
            <h1 class="text-3xl font-bold mb-4 mt-2">
              {{ $t("views.home.subtitle") }}
            </h1>
            <p class="text-base mb-4 font-regular text-gray-200 md:pr-20">
              {{ $t("views.home.title") }}
            </p>
            <button
              class="text-base text-white rounded-full py-4 px-10 my-6 download-button"
            >
            <router-link to="/download"> {{ $t("download") }} </router-link>  
            </button>
            <div class="flex space-x-4">
              <a class="py-2" href="https://apps.apple.com/app/nock-instant-connections/id6476958614">
                <img
                  src="@/assets/ios-store-badge-en.svg"
                  alt="Download for iOS"
                  class="download-button-ios mx-auto"
                />
              </a>
              <!-- <button class="px-2 py-2">
                <img
                  src="@/assets/google-play-badge-en.png"
                  alt="Download for Google"
                  class="download-button-google mx-auto"
                />
              </button> -->
            </div>
          </div>
          <!-- Right Column -->
          <div class="md:w-1/2 mt-6 md:mt-0" id="first-section">
            <img
              :src="images[$i18n.locale].main"
              alt="Use nock"
              class="w-full mx-auto p-5"
            />
          </div>
        </div>
      </section>
      <!-- Fourth Section -->
      <section class="text-center pt-0 pb-16 px-10 bg-black max-w-screen-xl mx-auto">
        <div class="container mx-auto">
          <h1 class="text-2xl text-white font-bold mb-4">
            {{ $t("views.home.misionTitle") }}
          </h1>
          <p class="mb-2 text-base font-regular text-gray-200">
            {{ $t("views.home.misionDescription") }}
          </p>
        </div>
      </section>
      <!-- Intro Section -->
      <section class="bg-black text-white py-2 px-5">
        <div class="container mx-auto max-w-screen-xl">
          <div class="text-center">
            <h1 class="font-bold py-4 text-2xl">
              {{ $t("views.home.intro") }}
            </h1>
          </div>
        </div>
      </section>
      <section class="bg-black text-white py-6 px-5">
        <div class="container mx-auto max-w-screen-xl">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-20">
            <!-- Feature 1 -->
            <div class="text-center">
              <img
                :src="images[$i18n.locale].discovery"
                alt="Radar"
                class="w-full md:w-full p-5 mx-auto mb-4 md:mb-4"
              />
              <h2 class="font-bold py-4 text-xl">
                {{ $t("views.home.discoveryTitle") }}
              </h2>
              <p class="text-base font-regular text-gray-200">
                {{ $t("views.home.discoveryDescription") }}
              </p>
            </div>
            <!-- Feature 2 -->
            <div class="text-center">
              <img
                :src="images[$i18n.locale].chat"
                alt="Nock Nock"
                class="w-full md:w-full p-5 mx-auto mb-4 md:mb-4"
              />
              <h2 class="font-bold py-4 text-xl">
                {{ $t("views.home.chatTitle") }}
              </h2>
              <p class="text-base font-regular text-gray-200">
                {{ $t("views.home.chatDescription") }}
              </p>
            </div>
            <!-- Feature 3 -->
            <div class="text-center">
              <img
                :src="images[$i18n.locale].public"
                alt="Open Chat"
                class="w-full md:w-full p-5 mx-auto mb-4 md:mb-10"
              />
              <h2 class="font-bold py-4 text-xl">
                {{ $t("views.home.findTitle") }}
              </h2>
              <p class="text-base font-regular text-gray-200">
                {{ $t("views.home.findDescription") }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <!-- Fourth Section -->
      <section class="text-center py-16 px-5 bg-black">
        <div class="container mx-auto">
          <h2 class="text-xl text-white font-bold mb-4">
            {{ $t("views.home.learnTitle") }}
          </h2>
          <p class="mb-2 text-base font-regular text-gray-200">
            {{ $t("views.home.learnDescription") }}
          </p>
          <button
            class="text-base text-white rounded-full py-4 px-10 my-6 download-button"
          >
            <router-link to="/faqs">
              {{ $t("views.home.learnButton") }}</router-link
            >
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-view",
  data() {
    return {
      images: {
        es: {
          main: require("@/assets/home_es.png"),
          discovery: require("@/assets/discovery_es.png"),
          chat: require("@/assets/chat_es.png"),
          public: require("@/assets/public_es.png"),
        },
        en: {
          main: require("@/assets/home_en.png"),
          discovery: require("@/assets/discovery_en.png"),
          chat: require("@/assets/chat_en.png"),
          public: require("@/assets/public_en.png"),
        },
      },
    };
  },
};
</script>

<style scoped>
/* Add your styling here */
/* Responsive YouTube Video Aspect Ratio */
.aspect-w-16 {
  width: 100%;
}

.download-button-google {
  height: 57px !important;
}
.download-button-ios {
  height: 40px !important;
}

.aspect-h-9 {
  padding-bottom: 56.25%;
}

.download-button {
  color: #ffffff;
  transition: background-color 0.3s ease;
  background-color: #f30051;
}

.download-button:hover {
  color: #ffffff;
  background-color: rgb(195, 27, 75);
}
</style>
