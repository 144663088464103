<template>
  <div class="bg-white max-w-screen-2xl w-full max-w-screen-xl mx-auto py-10">
    <div
      class="lg:ml-40 lg:mr-40 md:ml-5 md:mr-5 ml-5 mr-5 rounded bg-white text-justify"
    >
      <div
        class="privacy-container lg:ml-40 lg:mr-40 md:ml-5 md:mr-5 ml-5 mr-5 pb-10 text-xs"
      >
        <div class="w-full">
          <router-link to="/" class="float-left">
            <img src="@/assets/nock_name_full_black.png" class="h-7 md:h-7"
          /></router-link>
        </div>
        <br />
        <br />
        <h1>{{ $t("views.delete.title") }}</h1>
        <p>
          {{ $t("views.delete.description1") }}
        </p>
        <p>
          {{ $t("views.delete.description2") }}
        </p>

        <h1>{{ $t("views.delete.titleSteps") }}</h1>
        <ul>
          <li>
            1. {{ $t("views.delete.step1") }} <br /><br />
            <div class="p-2">
              <img
                :src="images[$i18n.locale].step1Image"
                alt="Use nock"
                class="mx-auto md:w-1/2 sm:w-3/4"
              />
            </div>
          </li>
          <br />
          <li>
            2. {{ $t("views.delete.step2") }} <br /><br />
            <div class="p-2">
              <img
                :src="images[$i18n.locale].step2Image"
                alt="Use nock"
                class="mx-auto md:w-1/2 sm:w-3/4"
              />
            </div>
          </li>
          <li>
            3. {{ $t("views.delete.step3") }} <br /><br />
            <div class="p-2">
              <img
                :src="images[$i18n.locale].step3Image"
                alt="Use nock"
                class="mx-auto md:w-1/2 sm:w-3/4"
              />
            </div>
          </li>
          <li>
            4. {{ $t("views.delete.step4") }} <br /><br />
            <div class="p-2">
              <img
                :src="images[$i18n.locale].step4Image"
                alt="Use nock"
                class="mx-auto md:w-1/2 sm:w-3/4"
              />
            </div>
          </li>
          <br />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "delete-account-view",
  data() {
    return {
      images: {
        es: {
          step1Image: require("@/assets/delete-account-step1-es.png"),
          step2Image: require("@/assets/delete-account-step2-es.png"),
          step3Image: require("@/assets/delete-account-step3-es.png"),
          step4Image: require("@/assets/delete-account-step4-es.png"),
        },
        en: {
          step1Image: require("@/assets/delete-account-step1.png"),
          step2Image: require("@/assets/delete-account-step2.png"),
          step3Image: require("@/assets/delete-account-step3.png"),
          step4Image: require("@/assets/delete-account-step4.png"),
        },
      },
    };
  },
};
</script>

<style scoped>
.privacy-container h1 {
  line-height: 1.2;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  color: black;
  margin-top: 2rem;
  margin-bottom: 1.3rem;
}

.privacy-container p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.privacy-container ul li {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
