import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import VueGtag from 'vue-gtag';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import './assets/css/tailwind.css';

library.add(faInstagram);
library.add(faFacebookF);
library.add(faTiktok);

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(VueGtag, {
    pageTrackerEnabled: true,
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-0L8VXEJ33B" }
})
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount('#app');
