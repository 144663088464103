import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import FAQs from '../views/FAQs.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'
import Eula from '../views/Eula.vue'
import Cookie from '../views/Cookie.vue'
import DeleteAccount from '../views/DeleteAccount.vue'
import Download from '../views/Download.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/faqs', component: FAQs },
    { path: '/terms', component: Terms },
    { path: '/privacy', component: Privacy },
    { path: '/eula', component: Eula },
    { path: '/cookie', component: Cookie },
    { path: '/delete', component: DeleteAccount },
    { path: '/download', component: Download },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;