<template>
  <div id="app">
    <Navbar v-if="!hideHeader && !isDownload" />
    <Header v-if="hideHeader && !isDownload" />
    <router-view :class="{ 'mt-10  md:py-8': !hideHeader }" class="bg-black" />
    <BottomFooter v-if="!hideHeader" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Header from "@/components/Header.vue";
import BottomFooter from "@/components/BottomFooter.vue";
import { useRoute } from "vue-router";

export default {
  name: "nock-website",
  components: {
    Navbar,
    BottomFooter,
    Header,
  },

  computed: {
    hideHeader() {
      const route = useRoute();
      return route.query.hide === "true";
    },
    isDownload() {
      const route = useRoute();
      return route.path === "/download";
    },
  },
};
</script>

<style>
body {
  background-color: black !important;
}
</style>
