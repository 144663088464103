<template>
  <header class="bg-black w-full mx-auto fixed top-0 pb-5">
    <div class="w-full max-w-screen-xl mx-auto p-5 without-pr center-header">
      <router-link to="/" class="float-left">
        <img src="@/assets/nock_name_full_white.png" class="h-7 md:h-7"
      /></router-link>
      <Transition>
        <div v-if="showScrollButton" class="download">
          <button class="text-xs text-white rounded-full py-2 px-5 float-right">
            <router-link to="/download"> {{ $t("download") }}</router-link>
          </button>
        </div>
      </Transition>
    </div>
  </header>
</template>

<script>
export default {
  name: "navbar-component",
  data() {
    return {
      showScrollButton: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollableElement = document.getElementById("first-section");
      if (!!scrollableElement && !!scrollableElement.clientHeight) {
        this.showScrollButton = window.scrollY > scrollableElement.clientHeight;
      } else {
        this.showScrollButton = true;
      }
    },
  },
};
</script>

<style scoped>
.center-header {
  vertical-align: middle;
  text-align: center;
}
button {
  color: #ffffff;
  transition: background-color 0.3s ease;
  background-color: #f30051;
}

button:hover {
  color: #ffffff;
  background-color: rgb(195, 27, 75);
}

.download {
  opacity: 1;
  transition: opacity 0.5s;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
