<!-- Footer.vue -->
<template>
  <footer class="bg-black">
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
      <div class="sm:flex sm:items-center sm:justify-between">
        <a
          href="https://getnock.com/"
          class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse hidden-mobile"
        >
          <img
            src="@/assets/nock_app_logo_mini.png"
            class="h-8"
            alt="Nock Logo"
          />
          <p class="text-xs text-gray-500">{{ $t("madeIn") }}</p>
        </a>
        <ul
          class="flex flex-wrap items-center mb-6 text-xs text-white sm:mb-1 footer-menu"
        >
          <li>
            <router-link to="/faqs" class="me-4 md:me-6">{{
              $t("faqs")
            }}</router-link>
          </li>
          <li>
            <router-link to="/terms" class="me-4 md:me-6">{{
              $t("terms")
            }}</router-link>
          </li>
          <li>
            <router-link to="/privacy" class="me-4 md:me-6">{{
              $t("privacy")
            }}</router-link>
          </li>
          <li>
            <router-link to="/cookie" class="me-4 md:me-6">{{
              $t("cookie")
            }}</router-link>
          </li>
          <li>
            <select
              class="locale-selector"
              v-model="selectedLocale"
              @change="changeLocale"
            >
              <option
                v-for="(value, key) in supportedLocales"
                :key="key"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
          </li>
        </ul>
      </div>
      <hr
        class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"
      />
      <div class="mx-auto flex justify-center">
        <!-- Logo de Instagram -->
        <div class="mx-2">
          <a
            href="https://www.instagram.com/get.nock?igsh=MWQ1cGJ3emxwMmswaw%3D%3D&utm_source=qr"
            target="_blank"
          >
            <font-awesome-icon
              :icon="['fab', 'instagram']"
              class="text-3xl text-white"
            />
          </a>
        </div>
        <!-- Logo de TikTok -->
        <div class="mx-2">
          <a
            href="https://www.tiktok.com/@get.nock?_t=8mOAKXY8wUP&_r=1"
            target="_blank"
          >
            <font-awesome-icon
              :icon="['fab', 'tiktok']"
              class="text-2xl text-white"
              style="margin-top: 0.23rem"
            />
          </a>
        </div>
        <!-- Logo de Facebook -->
        <div class="mx-2">
          <a
            href="https://www.facebook.com/share/M5TMQCB9zKL9rLTx/?mibextid=LQQJ4d"
            target="_blank"
          >
            <font-awesome-icon
              :icon="['fab', 'facebook-f']"
              class="text-2xl text-white"
              style="margin-top: 0.12rem"
            />
          </a>
        </div>
      </div>
      <br/>
      <span class="block text-xs text-gray-500 sm:text-center text-center"
        >{{ $t("copyRight") }} -
        <a href="mailto:contact@getnock.com" class="text-gray-700">
          contact@getnock.com</a
        ></span
      >
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-component",
  data() {
    return {
      supportedLocales: {
        en: "English",
        es: "Español",
      },
      selectedLocale: this.$i18n.locale?.split("-")[0] ?? "en",
    };
  },
  methods: {
    changeLocale() {
      this.$i18n.locale = this.selectedLocale;
    },
  },
};
</script>

<style scoped>
footer {
  background-color: #000;
}
footer a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #969696;
}

.footer-menu {
  align-items: center;
  justify-content: center;
}

.locale-selector {
  background-color: black;
  color: white;
}
</style>
